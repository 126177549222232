import React, {useState, useEffect} from 'react';
import {Card, CardAction} from './common/Card';
import {HelpCard, GradesCard, AverageCard, ResetButton} from './common/Grades';
import {useCookies} from 'react-cookie';
import Helmet from 'react-helmet';

const noWeightsMessage = 'Podaj wagi oddzielone przecinkiem.';

function WeightsCard(props) {
  function handleSubmit(event) {
    event.preventDefault();
    const input = event.target.children[0].children[0];
    const value = input.value;
    input.value = '';
    let weights = value.split(',');
    weights = weights.map((weight) => weight.trim());
    weights = weights.filter((weight) => weight && !isNaN(weight));
    weights = weights.map((weight) => Number(weight));

    if (weights.length === 0) {
      alert(noWeightsMessage);
      return;
    }
    props.onSubmit(weights);
  }

  return (
    <Card heading="Wagi ocen" className="weights__set">
      <p>Zanim będziesz mógł obliczyć swoją średnią musisz podać nam wagi ocen używane w&nbsp;twojej szkole. Wypisz je po przecinku pamiętając, że ułamki dziesiętne (np. 0.6) zapisuje się za pomocą kropki. Po pierwszym razie kalkulator zapamięta wagi na danym urządzeniu.</p>
      <form onSubmit={handleSubmit} noValidate>
        <div className="text-field">
          <input type="text" name="weights" placeholder="1, 2, 3..." autoComplete="off" className="weights__input text-field__input" />
          <label htmlFor="weights" className="text-field__label">Wagi</label>
        </div>
        <button type="submit" className="button color--indigo text--white">Zatwierdź</button>
      </form>
    </Card>
  );
}

function useSavedWeights() {
  const cookieName = 'avgCalcData';
  const [weights, setWeights] = useState();
  const [cookies, setCookie] = useCookies([cookieName]);

  useEffect(() => {
    if (cookieName in cookies) {
      setWeights(cookies[cookieName].split(',').map((weight) => Number(weight)));
    }
  }, [cookies]);

  function setNewWeights(newWeigths) {
    if (newWeigths) {
      setCookie(cookieName, newWeigths.join(','));
    }
    setWeights(newWeigths);
  }

  return [weights, setNewWeights];
}

function WeightedAveragePage() {
  const helpActions = [
    <CardAction key={1} onClick={handleWeightsChange} title="Ustaw nowe wagi">Zmień wagi</CardAction>,
    <CardAction key={2} link="/zwykla" title="Kalkulator zwykłej średniej">Zwykła średnia</CardAction>,
  ];

  const [grades, setGrades] = useState({});
  const [gradeKey, setGradeKey] = useState(0);
  const [weights, setWeights] = useSavedWeights();
  const [average, setAverage] = useState();

  useEffect(() => {
    if (!weights) return;
    let newGrades = {};
    weights.forEach((weight) => newGrades[weight] = [])
    setGrades(newGrades);
  }, [weights]);

  useEffect(() => {
    let numerator = 0;
    let denominator = 0;
    for (let i = 0; i < Object.keys(grades).length; i++) {
      const weight = weights[i];
      const weightGrades = grades[weight];
      const sum = weightGrades.reduce((acc, item) => acc + item.grade, 0);
      numerator += sum * weight;
      denominator += weightGrades.length * weight;
    }
    setAverage(numerator / denominator);
  }, [grades, weights]);

  function handleWeightsChange() {
    setWeights(null);
    setGrades({});
  }

  function handleWeightsSubmit(weights) {
    setWeights(weights);
  }

  function handleGradeAdd(weight, grade) {
    let newGrades = {};
    Object.assign(newGrades, grades);
    newGrades[weight] = newGrades[weight].concat([{key: gradeKey, grade}]);
    setGradeKey(gradeKey + 1);
    setGrades(newGrades);
  }

  function handleGradeDelete(weight, key) {
    let newGrades = {};
    Object.assign(newGrades, grades);
    newGrades[weight] = newGrades[weight].filter((item) => item.key !== key);
    setGrades(newGrades);
  }

  function handleGradesReset() {
    let newGrades = {};
    weights.forEach((weight) => newGrades[weight] = [])
    setGrades(newGrades);
    setAverage(null);
  }

  function isAverage() {
    return average || average === 0;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Średnia ważona – Kalkulator średniej ocen</title>
        <link rel="canonical" href="https://srednia.com/wazona" />
      </Helmet>
      <h2 className="visuallyhidden">Średnia zwykła (arytmetyczna)</h2>
      <div className="grid">
        {!weights ? (
          <WeightsCard onSubmit={handleWeightsSubmit} />
        ) : (
          <React.Fragment>
            <HelpCard actions={helpActions} />
            {Object.keys(grades).map((weight) => 
              <GradesCard
                key={weight}
                cardKey={weight}
                heading={`Waga ${weight}`}
                grades={grades[weight]} 
                onSubmit={(grade) => handleGradeAdd(weight, grade)}
                onDelete={(key) => handleGradeDelete(weight, key)}
              />
            )}
          <AverageCard value={isAverage() && average.toFixed(2)} />
          {isAverage() && <ResetButton onClick={handleGradesReset} />}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

export default WeightedAveragePage;
