import React from 'react';
import {Link} from 'react-router-dom';
import '../../styles/main.scss';

function CardAction(props) {
  if (props.link) {
    return (
      <Link to={props.link} title={props.title} className="card__button color--white text--indigo">{props.children}</Link>
    );
  } else {
    return (
      <button onClick={props.onClick} title={props.title} className="card__button color--white text--indigo">{props.children}</button>
    );
  }
}

function Card(props) {
  return (
    <section className={"card color--white text--black " + props.className}>
      <h3 className="card__title">{props.heading}</h3>
      <div className="card__content">{props.children}</div>
      {props.actions && (<div className="card__actions">{props.actions}</div>)}
    </section>
  );
}

export {Card, CardAction};
