import React, {useEffect} from 'react';

function AdSense() {
  useEffect(() => {
    if (window) (window.adsbygoogle = window.adsbygoogle || []).push({});
  });

  if (window.navigator.onLine) {
    return (
      <div className="adsense">
        <ins class="adsbygoogle"
            style={{display: 'block'}}
            data-ad-client="ca-pub-1234234062441398"
            data-ad-slot="4809350862"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
      </div>
    );
  } else {
    return null;
  }
}

export default AdSense;
