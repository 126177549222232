import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import '../../styles/main.scss';

function MenuLink(props) {
  return <NavLink exact to={props.to} className="nav__link" activeClassName="nav__link--active">{props.children}</NavLink>
}

function Header() {
  return (
    <header className="header header--compact color--indigo text--white">
      <h1 className="header__title text--white">
        <Link to="/" className="header__link">Kalkulator średniej</Link>
      </h1>

      <nav className="nav">
        <h2 className="visuallyhidden">Menu główne</h2>

        <ul className="nav__list">
          <li className="nav__item"><MenuLink to="/">Start</MenuLink></li>
          <li className="nav__item"><MenuLink to="/wazona">Ważona</MenuLink></li>
          <li className="nav__item"><MenuLink to="/zwykla">Zwykła</MenuLink></li>
          <li className="nav__item"><MenuLink to="/jak-obliczyc">Jak obliczyć</MenuLink></li>
        </ul>
      </nav>
    </header>
  )
}

export default Header;
