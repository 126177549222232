import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Page from './components/common/Page';
import HomePage from './components/HomePage';
import AveragePage from './components/AveragePage';
import WeightedAveragePage from './components/WeightedAveragePage';
import HowToPage from './components/HowToPage';

function App() {
  return (
    <Page>
      <Switch>
        <Route path='/zwykla' component={AveragePage} />
        <Route path='/wazona' component={WeightedAveragePage} />
        <Route path='/jak-obliczyc' component={HowToPage} />
        <Route path='/' component={HomePage} />
      </Switch>
    </Page>
  );
}

export default App;
