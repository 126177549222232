import React from 'react';
import {Card, CardAction} from './common/Card';
import ArithmeticEquation from '../images/arithmetic-equation.png';
import ArithmeticExample from '../images/arithmetic-example.png';
import WeightedEquation from '../images/weighted-equation.png';
import WeightedExample from '../images/weighted-example.png';
import Helmet from 'react-helmet';

function Equation(props) {
  return <img className="equation" src={props.src} alt={props.title} title={props.title} />
}

function HowToPage() {
  const arithmeticActions = [
    <CardAction key={1} link="/zwykla" title="Przejdź do kalkulatora średniej arytmetycznej">Oblicz kalkulatorem</CardAction>,
  ];
  const weightedActions = [
    <CardAction key={1} link="/wazona" title="Przejdź do kalkulatora średniej ważonej">Oblicz kalkulatorem</CardAction>,
  ];
  return (
    <React.Fragment>
      <Helmet>
        <title>Jak obliczyć średnią? – Kalkulator średniej ocen</title>
        <link rel="canonical" href="https://srednia.com/jak-obliczyc" />
      </Helmet>
      <h2 className="visuallyhidden">Jak obliczyć średnią?</h2>
      <div className="grid">
        <Card key={1} heading="Czym właściwie jest ta średnia?">
          <p>Gdybyśmy chcieli w&nbsp;sposób naukowy opisać średnią, powiedzielibyśmy, że jest to metoda statystyczna mająca na celu znalezienie tendencji centralnej rozkładu. Nie jesteśmy jednak naukowcami i&nbsp;definicja ta pewnie niewiele nam mówi. Czym więc jest średnia? W szkole obliczanie średniej służy przedstawieniu semestralnych lub rocznych wyników danego ucznia w&nbsp;postaci jednej oceny tak, aby była ona sprawiedliwa i&nbsp;usystematyzowana (chociaż na temat sprawiedliwości tej metody można by polemizować). Do tej pory średnia jest stosowana na wielu poziomach edukacji, więc powinniśmy się raczej zastanawiać jak ją obliczyć, a&nbsp;nie zgłębiać jej techniczną naturę. Wyróżniamy dwa najczęściej stosowane rodzaje średniej: <strong className="typography--strong">arytmetyczną</strong> i&nbsp;<strong className="typography--strong">ważoną</strong>.</p>
        </Card>
        <Card key={2} heading="Jak obliczyć średnią arytmetyczną?" actions={arithmeticActions}>
          <p>Gdy w języku potocznym pada słowo <em className="typography--emphasis">średnia</em>, zwykle mamy na myśli właśnie średnią arytmetyczną. Jest to suma naszych ocen podzielona przez ich ilość. Wzór, który najprawdopodobniej wszyscy znamy, wygląda tak:</p>
          <Equation src={ArithmeticEquation} title="Wzór na średnią arytmetyczną ocen" />
          <p>A&nbsp;tak wygląda obliczanie średniej ucznia z&nbsp;ocenami: 4, 3, 4, 5 i&nbsp;3:</p>
          <Equation src={ArithmeticExample} title="Przykład obliczania średniej arytmetycznej ocen" />
        </Card>
        <Card key={3} heading="Jak obliczyć średnią ważoną?" actions={weightedActions}>
          <p>Średnia ważona jest już nieco bardziej skomplikowana od arytmetycznej, jednak bardzo często używana w&nbsp;szkołach ponadgimnazjalnych. Aby ją obliczyć dodajemy do siebie sumy ocen o&nbsp;danej wadze pomnożone przez tę wagę i&nbsp;dzielimy przez sumę iloczynów wagi i&nbsp;ocen danej wagi. Opis może być niezbyt zrozumiały, lecz wzór powinien rozwiać wszelkie wątpliwości:</p>
          <Equation src={WeightedEquation} title="Wzór na średnią ważoną ocen" />
          <p>Tak obliczymy średnią ucznia z ocenami wagi 1: 3, 4 i&nbsp;4, oraz wagi 2: 5, 3, 4 i&nbsp;5:</p>
          <Equation src={WeightedExample} title="Przykład obliczania średniej ważonej ocen" />
        </Card>
      </div>
    </React.Fragment>
  );
}

export default HowToPage;
