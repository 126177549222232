import React from 'react';
import { Card, CardAction } from './common/Card';
import Helmet from 'react-helmet';

function Home() {
  const homeActions = [
    <CardAction key={1} link="/wazona" title="Kalkulator średniej ważonej">Średnia ważona</CardAction>,
    <CardAction key={2} link="/zwykla" title="Kalkulator średniej arytmetycznej">Średnia arytmetyczna</CardAction>,
    <CardAction key={3} link="/jak-obliczyc" title="Dowiedz się jak obliczyć średnią">Jak obliczyć średnią?</CardAction>,
  ]

  return (
    <React.Fragment>
      <Helmet>
        <title>Kalkulator średniej ocen</title>
        <link rel="canonical" href="https://srednia.com" />
      </Helmet>
      <h2 className="visuallyhidden">Rodzaje średniej</h2>
      <Card heading="Jaką średnią chcesz obliczyć?" actions={homeActions} className="grid__col grid__col--1">
        <p>Kalkulator średniej pozwala na obliczanie dwóch typów średniej: średniej ważonej i&nbsp;zwykłej średniej arytmetycznej. Jeśli twoje oceny mają różne wagi, masz do czynienia ze średnią ważoną. W&nbsp;przeciwnym wypadku wybierz zwykłą średnią. Możesz także dowiedzieć się czym jest średnia i&nbsp;jak obliczyć ją samemu.</p>
      </Card>
    </React.Fragment>
  );
}

export default Home;
