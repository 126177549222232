import React, {useState, useEffect} from 'react';
import {HelpCard, GradesCard, AverageCard, ResetButton} from './common/Grades';
import {CardAction} from '../components/common/Card';
import Helmet from 'react-helmet';

function AveragePage() {
  const helpActions = [
    <CardAction key={1} link="/wazona" title="Kalkulator średniej ważonej">Średnia ważona</CardAction>,
  ];

  const [grades, setGrades] = useState([]);
  const [gradeKey, setGradeKey] = useState(0);
  const [average, setAverage] = useState();

  useEffect(() => {
    const sum = grades.reduce((acc, item) => acc + item.grade, 0);
    setAverage(sum / grades.length);
  }, [grades]);

  function handleGradeAdd(grade) {
    setGrades(grades.concat([{key: gradeKey, grade}]));
    setGradeKey(gradeKey + 1);
  }

  function handleGradeDelete(key) {
    setGrades(grades.filter((item) => item.key !== key));
  }

  function handleGradesReset() {
    setGrades([]);
    setAverage(null);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Średnia zwykła (arytmetyczna) – Kalkulator średniej ocen</title>
        <link rel="canonical" href="https://srednia.com/zwykla" />
      </Helmet>
      <h2 className="visuallyhidden">Średnia zwykła (arytmetyczna)</h2>
      <div className="grid">
        <HelpCard actions={helpActions} />
        <GradesCard heading="Twoje oceny" grades={grades} onSubmit={handleGradeAdd} onDelete={handleGradeDelete} />
        <AverageCard value={(average || average === 0) && average.toFixed(2)} />
        {grades.length > 0 && <ResetButton onClick={handleGradesReset} />}
      </div>
    </React.Fragment>
  );
}

export default AveragePage;
