import React from 'react';
import '../../styles/main.scss';

function FooterLink(props) {
  return <a href={props.to} title={props.title} className="typography--link text--white">{props.children}</a>
}

function Footer() {
  return (
    <footer className="footer color--indigo text--white">
      <div className="footer__left">
        Autor strony: Bazyli Cyran; Znalazłeś błąd?&nbsp;
        <FooterLink to="mailto:kontakt@srednia.com" title="Zgłoś błąd">
          zgłoś!
        </FooterLink>
      </div>
      <div className="footer__right">
        Sprawdź&nbsp;
        <FooterLink to="/polityka-prywatnosci.html" title="Polityka prywatności strony.">
          politykę prywatności
        </FooterLink>.
      </div>
    </footer>
  )
}

export default Footer;
