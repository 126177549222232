import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import AdSense from "./AdSense";
import { Card } from "./Card";

function Page(props) {
  return (
    <React.Fragment>
      <Header />
      <main className="page__content">
        <article>
          <AdSense />
          <Card heading="Nowa aplikacja mobilna!">
            <p>
              Aplikacja mobilna na iOS oraz Androida posiada więcej funkcji:
              oceny z plusami i minusami, zapisywanie ocen. Wypróbuj:
            </p>
            <div style={{ display: "inlineblock" }}>
              <a
                href="https://apps.apple.com/pl/app/kalkulator-%C5%9Bredniej-ocen/id6471897036?itsct=apps_box_badge&amp;itscg=30200"
                style={{
                  overflow: "hidden",
                }}
              >
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pl-pl?size=250x83&amp;releaseDate=1700870400"
                  alt="Download on the App Store"
                  style={{
                    borderRadius: "13px",
                    width: "135px",
                    height: "45px",
                  }}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=dev.cyran.srednia&utm_source=web&utm_campaign=badge&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  className="google_play_badge"
                  alt="pobierz z Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/pl_badge_web_generic.png"
                  style={{ marginBottom: "-6px" }}
                />
              </a>
            </div>
          </Card>
          {props.children}
        </article>
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default Page;
