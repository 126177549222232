import React from 'react';
import {Card} from './Card';

const incorrectGradeMessage = "Podaj poprawną ocenę.";

function HelpCard(props) {
  return (
    <Card heading="Pomoc" actions={props.actions} className="grid__col grid__col--1">
      <p>Oceny podawaj pojedynczo, po wprowadzeniu każdej zatwierdź klikając "dodaj" lub naciskając enter.</p>
      <p>Aby usunąć pojedynczą ocenę po prostu na nią kliknij.</p>
    </Card>
  );
}

function Grade(props) {
  return (
    <li className="grades__item tooltip--bottom" data-tooltip={`Kliknij aby usunąć: ${props.value}`} onClick={props.onClick}>
      {props.value}
    </li>);
}

function GradesCard(props) {
  const key = props.cardKey || 0;

  function handleSubmit(event) {
    event.preventDefault();
    const field = event.target.children[0].children[0];
    const value = field.value;
    field.value = "";
    field.focus();

    if (!value) {
      alert(incorrectGradeMessage);
      return;
    }
    props.onSubmit(Number(value));
  }

  return (
    <Card heading={props.heading} className="grid__col--1 grid__col--m--1-2">
      <p>Podaj pojedynczo oceny:</p>
      <form onSubmit={handleSubmit} noValidate>
        <div className="text-field">
          <input type="number" placeholder="5" id={"grades" + key} className="grades__input text-field__input" />
          <label htmlFor={"grades" + key} className="text-field__label">Ocena</label>
        </div>
        <button type="submit" className="button color--indigo text--white">Dodaj</button>
      </form>
      <ul className="grades__list">
        {props.grades.map((item) => 
          <Grade value={item.grade} onClick={() => props.onDelete(item.key)} key={item.key} />         
        )}
      </ul>
    </Card>
  );
}

function AverageCard(props) {
  return (
    <Card heading="Twoja średnia" className="grid__col--1 grid__col--m--1-2">
      <p>Średnia z&nbsp;podanych ocen to:</p>
      <output className="grades__output text--indigo">{props.value ? props.value : '???'}</output>
    </Card>
  );
}

function ResetButton(props) {
  return <button className="grades__reset fab color--pink text--white" onClick={props.onClick}>Reset</button>;
}

export {HelpCard, GradesCard, AverageCard, ResetButton};
