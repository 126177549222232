import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import ReactGA from 'react-ga4';
import {createBrowserHistory} from 'history';

const history = createBrowserHistory();

ReactGA.initialize('G-C01J956S0W');
history.listen(location => {
  ReactGA.set({page: location.pathname});
  ReactGA.pageview(location.pathname);
})

ReactDOM.render(
  <BrowserRouter history={history}>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
